.company-tile {
  max-width: 400px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whatsapp-cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between icon and text */
  background-color: #128c7e; /* WhatsApp color */
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none; /* Remove underline from link */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin-top: 16px; /* Space above the button */
}

.text-center {
  text-align: center;
}

.sale-offer {
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
}

/* Styling for the social icons container */
.flex {
  display: flex;
  justify-content: center;
  gap: 24px; /* Space between icons */
  margin-top: 16px; /* Space above the social icons */
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* Styling for social icons */
.social-icon {
  font-size: 24px; /* Icon size */
  color: #6b7280; /* Default color for icons */
}

.social-icon:hover {
  color: #9ca3af; /* Hover color for icons */
}

/* Text and font styling */
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.rounded-full {
  border-radius: 9999px;
}

/* Text colors */
.text-gray-500 {
  color: #6b7280;
}

.text-gray-900 {
  color: #111827;
}

.hover\:text-gray-400:hover {
  color: #9ca3af;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .company-tile {
    max-width: 95%;
    padding: 20px;
  }
}
